require("../stylesheets/member")

window.addEventListener('load', (event) => {
  const backs = document.querySelectorAll('.history-back');
  backs.forEach((back) => {
    back.addEventListener('click', (ev) => {
      window.history.back();
    });
  });

  const checkboxes = document.querySelectorAll('.form-signup input[type="checkbox"]');
  const buttons = document.querySelectorAll('.form-signup button');
  checkboxes.forEach((checkbox) => {
    checkbox.addEventListener('click', (ev) => {
      // console.debug(ev);
      buttons.forEach((button) => {
        // console.debug(button)
        button.disabled = !ev.target.checked;
      });
    });
  });

  // enable/disable other field
  const relations = document.querySelectorAll('.has-related-field');
  relations.forEach((select) => {
    select.addEventListener('change', (ev) => {
      const relatedField = ev.target.dataset.relatedField;
      const unlockValue = ev.target.dataset.unlockValue;
      console.debug(relatedField);
      console.debug(unlockValue);

      const targetField = document.getElementById(relatedField);
      targetField.disabled = (ev.target.value != unlockValue);
    });
    select.dispatchEvent(new Event('change'));
  });

});

